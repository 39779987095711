import React from "react";
import { Typewriter } from "react-simple-typewriter";
import heroImage from "../Assets/Images/heroimage-min.png";
import "../Assets/CSS/HeroSection.css";
import Marquee from "react-fast-marquee";
import unilever from "../Assets/Images/unilever.svg";
import amber from "../Assets/Images/amber.svg";
import dabur from "../Assets/Images/daber.svg";
import toray from "../Assets/Images/torey.svg";
import hero from "../Assets/Images/hero.svg";
import tvs from "../Assets/Images/tvs.svg";


const HeroSection = () => {
  const rotatingWords = [
    "Production",
    "Quality",
    "Safety",
    "Maintenance",
    "Utility",
  ];

  return (
    <div className="heroBackGround">
      <img
        src={heroImage}
        alt=""
        className="w-full pt-[60px] sm:hidden block"
      />
      <div className="relative top-[0px] sm:top-[120px]">
        <div className="text-white text-[22px] sm:text-[32px] md:text-[38px] tracking-wide lg:text-[43px] font-poppins text-center px-[5px]">
          Df-OS unites{" "}
          <span className="text-gradientTrust">
            {" "}
            <Typewriter
              words={rotatingWords}
              loop={0}
              // cursor
              // cursorStyle="|"
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={1000}
            />{" "}
          </span>
          <br className="block sm:hidden" />
          at a single platform.
        </div>
        <div className="text-[#DADADA] text-[14px] md:text-[14px] lg:text-[18px] mt-[10px] text-center tracking-wide px-[20px]">
          Turning 1000-days digital transformation journey into a 15-days sprint
        </div>
      </div>
      <div>
        <div className="mx-[0px] lg:mx-[85px] mt-[0%] sm:mt-[50%] pt-[30px] sm:pt-[0px]">
        
          <Marquee className="py-[10px]">
            <img
              src={unilever}
              alt=""
              className="ml-[40px] lg:ml-[120px] 2xl:ml-[150px]"
            />
            <img
              src={amber}
              alt=""
              className="ml-[40px] lg:ml-[120px] 2xl:ml-[150px]"
            />
            <img
              src={dabur}
              alt=""
              className="ml-[40px] lg:ml-[120px] 2xl:ml-[150px]"
            />
            <img
              src={toray}
              alt=""
              className="ml-[40px] lg:ml-[120px] 2xl:ml-[150px]"
            />
            <img
              src={hero}
              alt=""
              className="ml-[40px] lg:ml-[120px] 2xl:ml-[150px]"
            />
            <img
              src={tvs}
              alt=""
              className="ml-[40px] lg:ml-[120px] 2xl:ml-[150px]"
            />
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
